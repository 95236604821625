@import "../base/settings";

.container {
  @include background-texture; }

.content-well {
  @include content-well; }

.services {
  &__image {
    width: 100%;
    height: auto;
    margin-bottom: 2rem; } }
