@import "../base/settings";

.our-clients {
  margin-top: 2rem;
  &__heading {
    @include heading($shade-four); }

  &__logos {
    align-items: center;
    div {
      margin-bottom: 1rem; }
    &__logo {
      height: auto;
      width: 100%; } }
  @media screen and (max-width: 45rem) {
    text-align: center; } }
