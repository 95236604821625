@import "../base/settings";

.content-well {
  @include content-well; }

.container {
  display: flex;
  align-items: center; }

.contact {
  border-top: .25rem solid $primary-color;
  background-image: url("../../images/textures/woodgrain_dark.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $shade-one;
  &__heading {
    @include heading($shade-one); }
  &__address, &__mail {
    display: flex;
    align-items: flex-start;
    span {
      font-size: 2rem;
      margin-right: 1rem; }
    h3, a {
      font-family: "Nunito Sans", sans-serif;
      font-weight: 300;
      font-size: 1.2rem;
      text-decoration: none;
      color: $shade-one; }
    a {
      &:hover {
        text-decoration: none;
        color: $secondary-color; } } }

  @media screen and (max-width: 991px) {
    &__phone {
      justify-content: space-around; } }
  @media screen and (max-width: 45rem) {
    text-align: center; }
  @media screen and (max-width: 575px) {
    &__address, &__mail {
      justify-content: space-around;
      margin-bottom: 1rem;
      &:last-of-type {
        margin-bottom: 0; } } } }
