// Color palette //
$primary-color: #FFA64D; // Neon Carrot
$secondary-color: #FF4D88; // French Rose
$tertiary-color: #4DC4FF; // Picton Blue

$shade-one: #EEEEEE; // White
$shade-two: #999999; // Aluminum
$shade-three: #454545; // Charcoal
$shade-four: #333333; // Mine Shaft

// Typography //
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300i,300,400,700|Nunito:900&display=swap');
@import url("https://use.typekit.net/nex1jvt.css"); // Sign Painter font for the creators names in about page

@mixin heading ($color: inherit) {
  color: $color;
  display: inline-block;
  text-transform: uppercase;
  font-family: Nunito, sans-serif;
  font-size: 2rem;
  font-weight: 900;
  border-bottom: 2px solid $color;
  margin-bottom: 2rem; }

// Mixins //
@mixin content-well {
  max-width: 60rem;
  margin: 0 auto;
  padding: 2rem; }

@mixin background-texture {
  height: 100%;
  width: 100%;
  background-image: url("../../images/textures/woodgrain_light.jpg");
  background-size: fill;
  background-position: center;
  background-repeat: repeat; }

@mixin button ($color: $secondary-color) {
  display: inline-block;
  position: relative;
  left: 50%;
  transform-origin: center center;
  transform: translateX(-50%) scale(1);
  padding: 1rem 3rem;
  color: $shade-one;
  background-color: $color;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  text-transform: uppercase;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  transition: all 300ms ease;
  &:hover {
    text-decoration: none;
    color: $shade-one;
    box-shadow: 2px 2px 4px transparentize($shade-four, .8);
    transform: translateX(-50%) scale(1.02); }
  &:active, &:focus {
    outline: none;
    box-shadow: none; }
  &:active {
    transform: translateX(-50%) scale(.98); } }
