@import "../base/settings";

.carousel {
  height: 30rem;
  width: 100%;
  display: block;
  position: relative;
  padding: 2rem;
  background-image: url("../../images/minimakers_box.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-bottom: 4px solid $tertiary-color;
  &:before { // Image overlay //
    display: block;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: transparentize($primary-color, .2); }

  &__content {
    @include content-well;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    text-align: left;
    margin: 0;
    h1 {
      font-family: Nunito, sans-serif;
      font-weight: 900;
      font-size: 4rem;
      color: $shade-one;
      margin-bottom: 2rem; }
    h2 {
      font-family: "Nunito Sans", sans-serif;
      font-size: 2rem;
      font-weight: 400;
      color: $shade-one; } } }

@media screen and (max-width: 45rem) {
  .carousel {
    height: 40vh;
    padding: 1rem;
    &__content {
      h1 {
        font-size: 3rem; }
      h2 {
        font-size: 1.5rem; } } } }

@media screen and (max-width: 32rem) {
  .carousel {
    &__content {
      h1 {
        font-size: 2rem; }
      h2 {
        font-size: 1rem; } } } }

@media screen and (max-width: 25rem) {
  .carousel {
    height: 58vh; } }
