@import "../base/settings";

.content-well {
  @include content-well; }

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.footer {
  background-color: $shade-one;
  width: 100%;
  padding: 2rem 0; }

.company-details {
  display: flex;
  align-items: center;
  color: $shade-four;

  &__logo {
    height: 5rem;
    width: 5rem;
    margin-right: 2rem; }

  &__info {
    display: inline-block;
    h2 {
      font-family: "Nunito", sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
      text-transform: uppercase;
      margin-bottom: .5rem; }
    h4 {
      font-family: "Nunito Sans", sans-serif;
      font-weight: 300;
      font-size: 1rem; } } }

.footer-nav {
  a {
    display: block;
    text-decoration: none;
    color: $shade-four;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1rem;
    font-weight: 300;
    text-align: right;
    transition: 100ms;
    &:hover {
      text-decoration: none;
      color: $secondary-color; }
    &:active {
      transform: scale(.95); } } }

.social-media {
  margin-top: 1rem;
  a {
    cursor: pointer;
    display: inline;
    margin-right: 1rem;
    &:last-of-type {
      margin-right: 0; }
    img {
      height: 2rem;
      width: auto; } } }

@media screen and (max-width: 40rem) {
  .company-details {
    margin: 0 auto;
    &__logo {
      display: none; }
    &__info {
      h2 {
        font-size: 1.5rem; }
      h4 {
        font-size: 1rem; } } }

  .footer-nav {
    display: none; } }

@media screen and (min-width: 30rem) {
  .company-details {
    margin: 0;
    &__logo {
      display: block; } } }
