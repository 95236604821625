@import "../base/settings";

.container {
  @include background-texture; }

.content-well {
  @include content-well; }

.services {
  text-align: left;
  margin-bottom: 2rem;
  &--mobile {
    display: none; }

  &__heading {
    @include heading($shade-four); } }


.projects {
  &__heading {
    @include heading($shade-four); }

  &__brief {
    margin-bottom: 2rem; }

  &__project-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: calc(100% - 15px); // Subtracts column gutter
    width: calc(100% - 30px); // subtracts column gutter
    z-index: 500;
    background-color: transparentize($tertiary-color, .2);
    transition: 300ms ease;
    &:hover, &:active, &:focus {
      opacity: 0; }
    span {
      font-size: 3rem;
      color: $shade-one; } }

  &__project {
    margin-bottom: 1rem !important;
    &__project-image {
      img {
        display: block;
        width: 100%;
        height: 10rem;
        object-fit: cover;
        object-position: bottom center; } }

    &__client-image {
      height: 10rem;
      display: flex;
      justify-content: space-around;
      padding: 2rem !important;
      background-color: $shade-one;
      img {
        align-self: center;
        height: auto;
        max-height: 100%;
        width: auto;
        max-width: 100%; } }

    &__text {
      padding: 2rem !important;
      background-color: $shade-one;
      display: flex;
      justify-content: space-around;
      align-items: center;
      p {
        background-color: $shade-one;
        font-family: "Nunito Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        margin: 0; } } }

  &__button {
    @include button; } }

@media screen and (max-width: 45rem) {
  .services {
    text-align: center; }
  .projects {
    &__heading {
      position: relative;
      left: 50%;
      transform: translateX(-50%); }

    &__project {
      &__client-image {
        padding: 1rem !important; }
      &__text {
        padding: 1rem !important; } }

    &__project-3, &__project-6 {
      display: none !important; } } }
