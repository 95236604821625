@import "../base/settings";

.header {
  background-color: $shade-four;
  width: 100%;
  &__content-well {
    @include content-well;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  &__logo {
    z-index: 300;
    position: relative;
    top: 1rem;
    height: 5rem;
    margin-right: 2rem;
    cursor: pointer; }

  &__nav {
    a {
      font-family: "Nunito Sans", sans-serif;
      font-size: 1.5rem;
      font-weight: 400;
      text-transform: uppercase;
      color: $shade-one;
      transition: all 300 ease;
      &:hover {
        color: $secondary-color;
        text-decoration: none;
        transition: all 300 ease; } }

    &--desktop {
      a {
        margin-right: 2rem;
        &:last-of-type {
          margin-right: 0; } }
      @media screen and (max-width: 45rem) {
        display: none; } }

    &--mobile {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: 300ms;
      a {
        display: block;
        text-align: center;
        margin-bottom: .5rem;
        &:last-of-type {
          margin-bottom: 0; } }

      &__overlay {
        display: none;
        opacity: 0;
        z-index: 1000;
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: transparentize($shade-four, 0.1);
        transition: 2000ms;

        &--active {
          display: block;
          opacity: 1; } } } }

  &__menu-toggle {
    display: none;
    transform-origin: center center;
    transform: rotate(0deg);
    height: 2rem;
    width: 3rem;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 300ms ease;
    span {
      height: 4px;
      width: 3rem;
      background-color: $shade-one;
      transition: width 200ms ease;
      &:nth-child(1) {
        width: 80%;
        transition-delay: 200ms; }
      &:nth-child(2) {
        width: 100%; }
      &:nth-child(3) {
        width: 60%;
        transition-delay: 400ms; }
      &:nth-child(4) {
        position: absolute;
        top: -.5rem;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        width: 4px;
        height: 0;
        transition: height 400ms; } }

    @media screen and (max-width: 45rem) {
      display: flex; }

    &--toggled {
      z-index: 1100;
      transform: rotate(45deg);
      transition-delay: 400ms;
      span:nth-child(1) {
        width: 0;
        transition: 200ms ease; }
      span:nth-child(3) {
        width: 0;
        transition: 200ms ease;
        transition-delay: 150ms; }
      span:nth-child(4) {
        height: 3rem;
        transition: height 200ms ease;
        transition-delay: 200ms; } } } }
