@import "../base/settings";

.bread {
  position: relative;
  padding: 2rem 1rem;
  text-align: center;
  color: $shade-one;
  border-bottom: .25rem solid $tertiary-color;
  &:before {
    display: block;
    position: absolute;
    z-index: 10;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, $secondary-color, $primary-color);
    opacity: .8; }

  &__heading {
    position: relative;
    z-index: 100;
    @include heading;
    border-bottom: none;
    margin-bottom: 1rem; }

  &__crumbs {
    position: relative;
    z-index: 100;
    text-transform: uppercase;
    font-family: "Nunito Sans", sans-serif;
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 0; }

  &--services, &--projects, &--about, &--contact {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; }

  &--services {
    background-image: url("../../images/breadcrumbs/breadcrumb_services.jpg"); }

  &--projects {
    background-image: url("../../images/breadcrumbs/breadcrumb_projects.jpg"); }

  &--about {
    background-image: url("../../images/breadcrumbs/breadcrumb_about.jpg"); }

  &--contact {
    background-image: url("../../images/breadcrumbs/breadcrumb_contact.jpg"); } }

@media screen and (max-width: 45rem) {
  .bread {
    text-align: center !important; } }
