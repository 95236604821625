@import "../base/settings";

.spacer {
  display: block;
  height: 1rem; }

.services {
  text-align: left;
  margin-bottom: 2rem;
  &--desktop {
    display: block; }
  &--mobile {
    display: none; }

  &__heading {
    @include heading($shade-four); }

  &__list-item {
    &--desktop {
      background-color: $shade-one;
      text-transform: uppercase;
      font-family: "Nunito Sans", sans-serif;
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: .5rem;
      cursor: pointer;
      transition: 200ms;
      &:last-of-type {
        margin-bottom: 0; }
      &:focus {
        background-color: green; }
      &:hover {
        background-color: $primary-color; }
      a {
        padding: 1rem;
        text-decoration: none;
        color: $shade-two;
        transition: 200ms;
        &:hover {
          color: $shade-three; } } }

    &--mobile {
      border: none !important;
      border-top: 2px solid $primary-color !important;
      border-radius: 0 !important;
      text-transform: uppercase;
      font-family: "Nunito Sans" sans-serif;
      font-size: 1rem;
      font-weight: 600;
      color: $shade-two;
      background-color: $shade-one !important;
      &:first-of-type {
        border-top: none !important; } } }

  &__description {
    &--desktop {
      background-color: $shade-one;
      height: 100%;
      padding: 2rem 4rem;
      display: flex;
      align-items: center;
      h2 {
        font-family: "Nunito Sans", sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        color: $shade-four; }
      p {
        font-family: "Nunito Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        color: $shade-four;
        margin: 0; } }
    &--mobile {
      background-color: none;
      text-transform: none;
      font-weight: 400;
      color: $shade-four;
      background-color: $shade-one;
      text-align: left;
      h2 {
        font-size: 1.5rem; }
      p {
        font-size: 1rem; } } } }

@media screen and (max-width: 45rem) {
  .services {
    text-align: center;
    &--desktop {
      display: none; }

    &--mobile {
      display: block; } } }
