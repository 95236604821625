@import "../base/settings";

.container {
  @include background-texture; }

.content-well {
  @include content-well; }

.spacer {
  display: block;
  height: 1rem; }

.about {
  padding: 2rem;
  background-color: $shade-one;
  margin-bottom: 2rem;
  &__text {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 300;
    font-size: 1rem;
    word-wrap: break-word;
    hyphens: auto;
    margin-bottom: 0; } }

.creators {
  @media screen and (max-width: 45rem) {
    text-align: center; }
  &__heading {
    @include heading($shade-four); }
  &__creator {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0; }
    &__image {
      img {
        display: block;
        height: auto;
        width: 100%;
        max-width: 200px;
        margin: 0 auto;
        border-radius: 100%;
        border: 2px solid $secondary-color; } }
    &__text {
      padding: 0 2rem !important;
      text-align: center;
      div {
        display: block;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        span {
          font-family: "house-script", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 2rem;
          color: $secondary-color;
          margin-bottom: 0; }
        p {
          font-family: "Nunito Sans", sans-serif;
          font-style: italic;
          font-weight: 300;
          font-size: 1rem; } } } } }
